import "./spinner.styles.css";
import { PuffLoader } from "react-spinners";
import { SpinnerProps } from "../../../services/types";

export function PuffSpinner(props: SpinnerProps) {
  return (
    <div className={` default-spinner ${props.divClassName}`}>
      <PuffLoader
        loading={props.loading}
        size={props.size ?? 120}
        cssOverride={props.style}
        color={props.color ?? "#7D49F5"}
        speedMultiplier={props.multiplier ?? 1.3}
      />
    </div>
  );
}
