export const timeInterval: number = 800;
export const timeIntervalSecond: number = 3000;
export const timeIntervalThird: number = 8000;

export const yearTitle: string = "Ano atual";
export const monthTitle: string = "Mês atual";
export const dayTitle: string = "Dia atual";

//Generate a new symbol - > https://developers.arcgis.com/javascript/3/samples/portal_symbols/index.html

const arcgisSymbolsAPI = "https://static.arcgis.com/images/Symbols/";

export const darkPinkFireFly: string = `${arcgisSymbolsAPI}/Firefly/FireflyC2.png`;
export const ligthBlueFireFly: string = `${arcgisSymbolsAPI}Firefly/FireflyC9.png`;
export const violetFireFly: string = `${arcgisSymbolsAPI}Firefly/FireflyC4.png`;
export const greenPersonMarker = `${arcgisSymbolsAPI}Government/Known-Offenders.png`;

export const googleApiKey: string = "AIzaSyAF_3FSj6mOjsfMyguMBp3x2zMmXaAfwTM&q";
export const esriApiKey: string =
  "AAPKb0cc29f92e754b1fb32367f9c9072e4cceflA8iQ-Z9yAXN4vJ8snmWiwaEjmOS-rk68OwffP0YmNcKzl9PtpLbv7Rf0vcM7";

export const orderApprovedTitle: string = "Aprovada";
export const ordersApprovedTitle: string = "Aprovadas";
export const orderReleasedTitle: string = "Distribução";
export const ordersReleasedTitle: string = "Distribuídas";
export const orderSentForApprovalTitle: string = "Enviada para aprovação";
export const ordersSentForApprovalTitle: string = "Enviadas para aprovação";

export const configOptions = {
  operationalDetails: 1,
  operationalStatus: 2,
  operationalEnv: 3,
  operatingLocations: 4,
  operatingInformation: 5,
};
