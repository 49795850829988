import { createContext, useContext, useEffect, useState } from "react";
import { timeIntervalThird } from "../services/const";

export type TimedEventConfig<T, U> = {
  data: U;
  events: T[];
};

/* Timing Context */

type TimingContextType = {
  paused: boolean;
  setPaused: (paused: boolean) => void;
};

const TimingContext = createContext<TimingContextType>({
  paused: false,
  setPaused: () => {},
});

export const useTimingContext = () => useContext(TimingContext);

export const TimingProvider = ({ children }: { children: React.ReactNode }) => {
  const [paused, setPaused] = useState(false);
  return (
    <TimingContext.Provider value={{ paused, setPaused }}>
      {children}
    </TimingContext.Provider>
  );
};

/* Time Interval Context */

type IntervalContextType = {
  interval: number;
  setInterval: (interval: number) => void;
};

const IntervalContext = createContext<IntervalContextType>({
  interval: timeIntervalThird,
  setInterval: () => {},
});

export const useTimeIntervalContext = () => useContext(IntervalContext);

export const TimeIntervalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [interval, setInterval] = useState(timeIntervalThird);
  return (
    <IntervalContext.Provider value={{ interval, setInterval }}>
      {children}
    </IntervalContext.Provider>
  );
};

/* Timed Hooks */

export function useInterval<T>(
  callback: (item: T, index: number) => void,
  data: T[],
  deps: any[]
) {
  const context = useTimingContext();
  const delay = useTimeIntervalContext();
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (context.paused) return;
      const item = data && data[index];
      item && callback(item, index);
      setIndex(getNextIndex(index, data.length));
    }, delay.interval);
    return () => clearInterval(intervalId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, delay, callback, index, setIndex, data, context.paused]);
}

export function useStatetelessTimedEvents<TChild, TParent>(
  payload: TimedEventConfig<TChild, TParent>[],
  {
    parentIndex,
    childIndex,
    onParentIndexChanged,
    onChildIndexChanged,
  }: {
    parentIndex: number;
    childIndex: number;
    onParentIndexChanged: (index: number) => void;
    onChildIndexChanged: (index: number) => void;
  }
) {
  const context = useTimingContext();
  const interval = useTimeIntervalContext();

  useEffect(() => {
    if (context.paused) return;
    if (!payload?.length) return;
    const timer = setInterval(() => {
      if (!payload?.length) return;
      const parent = payload[parentIndex];
      if (parent) {
        const isLastChild = childIndex >= parent.events.length - 1;
        const size = parent.events.length;
        const parentSize = payload.length;
        const nextChildren = getNextIndex(childIndex, size);
        const nextParent = getNextIndex(parentIndex, parentSize);
        onChildIndexChanged(nextChildren);
        if (isLastChild) onParentIndexChanged(nextParent);
      } else {
        onParentIndexChanged(0);
      }
    }, interval.interval);
    return () => clearInterval(timer);
  }, [
    payload,
    interval,
    onChildIndexChanged,
    onParentIndexChanged,
    childIndex,
    parentIndex,
    context.paused,
  ]);
}

//TODO: Substituir os usos de useTimedEvents por useStatetlessTimedEvents
// Evento que deve ser executado a cada X segundos (0.5)
// Sempre que Y eventos forem executados, a próxima lista será executada
export function useTimedEvents<TChild, TParent>(
  payload: TimedEventConfig<TChild, TParent>[],
  handleParent: (parentData: TParent, index: number) => void,
  handleChild: (child: TChild, parentData: TParent, index: number) => void
) {
  const [parentIndex, setParentIndex] = useState(0);
  const [currentChildren, setCurrentChildren] = useState(0);
  const context = useTimingContext();
  const interval = useTimeIntervalContext();

  useEffect(() => {
    if (!payload?.length) return;
    const timer = setInterval(() => {
      if (context.paused) return;
      if (!payload?.length) return;
      const parent = payload[parentIndex];
      const child = parent && parent.events[currentChildren];

      child && handleChild(child, parent.data, currentChildren);
      if (parent) {
        const isLastChild = currentChildren >= parent.events.length - 1;
        if (currentChildren === 0) {
          handleParent(parent.data, parentIndex);
        }
        const size = parent.events.length;
        const parentSize = payload.length;
        const nextChildren = getNextIndex(currentChildren, size);
        const nextParent = getNextIndex(parentIndex, parentSize);
        setCurrentChildren(nextChildren);
        if (isLastChild) setParentIndex(nextParent);
      } else {
        setParentIndex(0);
      }
    }, interval.interval);
    return () => clearInterval(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    payload,
    handleParent,
    handleChild,
    interval,
    setCurrentChildren,
    setParentIndex,
    currentChildren,
    parentIndex,
  ]);
}

export function useThreeLevelTimedEvents<TChild, TParent, TGrandParent>(
  payload: TimedEventConfig<TimedEventConfig<TChild, TParent>, TGrandParent>[],
  handleGrandParent: (grandParentData: TGrandParent, index: number) => void,
  handleParent: (parentData: TParent, index: number) => void,
  handleChild: (child: TChild, parentData: TParent, index: number) => void
) {
  const [grandParentIndex, setGrandParentIndex] = useState(0);
  const context = useTimingContext();
  const timeIntervalContext = useTimeIntervalContext();

  function onParent(parentData: TParent, index: number) {
    handleParent(parentData, index);
    const grandParent = payload[grandParentIndex];
    if (index >= grandParent.events.length - 1) {
      const nextGrandParent = getNextIndex(grandParentIndex, payload.length);
      setGrandParentIndex(nextGrandParent);
      handleGrandParent(grandParent.data, grandParentIndex);
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (context.paused) return;
      if (!payload[grandParentIndex]?.events?.length) {
        if (payload[grandParentIndex].data) {
          const index = getNextIndex(grandParentIndex, payload.length);
          handleGrandParent(payload[grandParentIndex].data, index);
          setGrandParentIndex(index);
        }
      }
    }, timeIntervalContext.interval);
    return () => clearTimeout(timeout);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload, grandParentIndex]);

  useTimedEvents(
    payload[grandParentIndex]?.events ?? [],
    onParent,
    handleChild
  );
}

function getNextIndex(currentChildren: number, size: number) {
  return currentChildren < size - 1 ? currentChildren + 1 : 0;
}
