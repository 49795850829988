import axios from "axios";
import { MetaData } from "../types";
export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 1000000,
});
export const devInstance = axios.create({
  baseURL: process.env.REACT_APP_PS_API_URL,
  timeout: 10000,
});

export type BaseResponse<T> = {
  content: {
    data: T;
    count?: number;
    metadata?: MetaData;
  };
};
