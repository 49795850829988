import "./App.css";
import { useEffect } from "react";
import { Suspense, lazy } from "react";
import { applyToken, getToken } from "./services/http/login";
import { PuffSpinner } from "./components/atoms/spinner/spinner";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { TimeIntervalProvider, TimingProvider } from "./hooks/useTimedEvents";

const LoginPage = lazy(() => import("./pages/login-page"));
const TeamFloor = lazy(() => import("./pages/team-floor"));
const OrdersPeriod = lazy(() => import("./pages/orders-period"));
const FlowMonitoring = lazy(() => import("./pages/flow-monitoring"));
const PresenceMonitoring = lazy(() => import("./pages/presence-monitoring"));
const CostsRevenueStatisticalMonitoring = lazy(
  () => import("./pages/costs-revenue-statistical-monitoring")
);
const StatisticalMonitoring = lazy(
  () => import("./pages/statistical-monitoring")
);
const StatisticalMonitoringContract = lazy(
  () => import("./pages/statistical-monitoring-contract")
);
const ContractsRegions = lazy(() => import("./pages/contracts-regions"));
const OrdersServiceLocation = lazy(
  () => import("./pages/orders-serviceLocation")
);
const FloorSectorsLocation = lazy(
  () => import("./pages/floor-sectors-location")
);
const CostsRevenue = lazy(() => import("./pages/costs-revenue"));
const OverviewRevenueService = lazy(
  () => import("./pages/overview-revenue-service")
);
const FloorsMonitoredEntity = lazy(
  () => import("./pages/floors-monitored-entity")
);
const UnitCoveredArea = lazy(() => import("./pages/unit-covered-area"));
const MenuOptions = lazy(() => import("./pages/menu-options"));
const OrdersResult = lazy(() => import("./pages/orders-results"));
const TeamDetailsPage = lazy(() => import("./pages/team-details"));
const AnnualAnalysis = lazy(() => import("./pages/annual-analysis"));
const FloorsCostsRevenue = lazy(() => import("./pages/floors-costs-revenue"));
const ScheduleCostsRevenue = lazy(
  () => import("./pages/schedule-costs-revenue")
);
const MonitoringCostsRevenue = lazy(
  () => import("./pages/monitoring-costs-revenue")
);
const OrdersMonitoringPeriod = lazy(
  () => import("./pages/orders-monitoring-period")
);

const DistributionValuesLocation = lazy(
  () => import("./pages/distribution-values-location")
);
const CostsRevenueSectorsLocations = lazy(
  () => import("./pages/costs-revenue-sectors-locations")
);

function App() {
  useEffect(() => {
    const token = getToken();
    if (token) {
      applyToken(token);
    }
  }, []);
  return (
    <TimingProvider>
      <TimeIntervalProvider>
        <Router>
          <Suspense fallback={<LoaderContainer />}>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/team-floor" element={<TeamFloor />} />
              <Route path="/orders-period" element={<OrdersPeriod />} />
              <Route path="/flow-monitoring" element={<FlowMonitoring />} />
              <Route
                path="/presence-monitoring"
                element={<PresenceMonitoring />}
              />
              <Route
                path="/costs-revenue-statistical-monitoring"
                element={<CostsRevenueStatisticalMonitoring />}
              />
              <Route
                path="/statistical-monitoring"
                element={<StatisticalMonitoring />}
              />
              <Route
                path="/statistical-monitoring-contract"
                element={<StatisticalMonitoringContract />}
              /> 
              <Route path="/contracts-regions" element={<ContractsRegions />} />
              <Route
                path="/orders-service-location"
                element={<OrdersServiceLocation />}
              />
              <Route
                path="/floor-sectors-location"
                element={<FloorSectorsLocation />}
              />
              <Route path="/costs-revenue" element={<CostsRevenue />} />
              <Route
                path="/overview-revenue-service"
                element={<OverviewRevenueService />}
              />
              <Route
                path="/floors-monitored-entity"
                element={<FloorsMonitoredEntity />}
              />
              <Route path="/unit-covered-area" element={<UnitCoveredArea />} />
              <Route path="/team-details" element={<TeamDetailsPage />} />
              <Route path="/menu-options" element={<MenuOptions />} />
              <Route path="/annual-analysis" element={<AnnualAnalysis />} />
              <Route path="/orders-result" element={<OrdersResult />} />
              <Route
                path="/costs-revenue-sectors-locations"
                element={<CostsRevenueSectorsLocations />}
              />
              <Route
                path="/floors-costs-revenue"
                element={<FloorsCostsRevenue />}
              />
              <Route
                path="/os-monitoring-period"
                element={<OrdersMonitoringPeriod />}
              />
              <Route
                path="/monitoring-costs-revenue"
                element={<MonitoringCostsRevenue />}
              />
              <Route
                path="/costs-revenue-sectors-locations"
                element={<CostsRevenueSectorsLocations />}
              />
              <Route
                path="/schedule-costs-revenue"
                element={<ScheduleCostsRevenue />}
              />
              <Route
                path="/distributions-values-location"
                element={<DistributionValuesLocation />}
              />
              <Route
                path="/costs-revenue-sectors-locations"
                element={<CostsRevenueSectorsLocations />}
              />
            </Routes>
          </Suspense>
        </Router>
      </TimeIntervalProvider>
    </TimingProvider>
  );
}

function LoaderContainer() {
  return (
    <div className="puff-container">
      <PuffSpinner loading={true} size={200} />
    </div>
  );
}

export default App;
