import { AxiosInstance } from "axios";
import { BaseResponse, devInstance, instance } from ".";
import { LoginResponse } from "../types";

export function login(document: string, code: number) {
  return devInstance.post<BaseResponse<LoginResponse>>("/account/login/v2", {
    document,
    code,
  });
}

export function saveToken(token: string) {
  localStorage.setItem("token", token);
}

export function getToken() {
  return localStorage.getItem("token");
}

export function clearToken() {
  saveToken("");
  applyToken("");
}

export function applyToken(token: string) {
  function apply(client: AxiosInstance) {
    client.defaults.headers.common["Authorization"] = token ? `Bearer ${token}` : '';
  }
  apply(devInstance);
  apply(instance);
}
